<template>
    <m-main-layout>
        <add-button slot="right-btn" label="添加角色" v-if="dataList.length > 0" @click.native="$router.push({path:'/rule/group/add'})" rule="/rule/group/add"></add-button>
        <div class="page-content-main">
            <el-table :data="dataList" default-expand-all style="width: 100%" row-key="id" :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
                <el-table-column
                        prop="name"
                        label="角色名称">
                </el-table-column>
                <el-table-column
                        prop="status"
                        label="状态" style="font-size:12px;" width="250">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>{{scope.row.state === 0 ? '禁用' : '可用'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"></el-table-column>
                <el-table-column
                    prop="update_time"
                    label="修改时间">
                    <template slot-scope="scope">{{scope.row.update_time > 0 ? $moment.unix(scope.row.update_time).format('YYYY-MM-DD HH:mm:ss') : '-'}}</template>
                </el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <base-button size="small" @click.native="$router.push({path:'/rule/group/rule',query:{id:scope.row.id}})" rule="/rule/group/rule" icon="el-icon-lock">编辑权限</base-button>
                        <edit-button @click.native="$router.push({path:'/rule/group/edit',query:{id:scope.row.id}})" rule="/rule/group/edit"></edit-button>
                        <del-button @click.native="del(scope.row.id)" rule="/rule/group/del"></del-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center null-data f14 info" v-else>
                还没有角色，<el-button type="primary" size="small" @click="$router.push({path:'/rule/group/add'})">立即添加</el-button>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
    export default {
        name: "MemberList",
        data(){
            return{
                dataList: [],
                // totalPage:0,
                // totalCount:0,
                // pageNum:20,
                // page:1,
            }
        },
        methods:{
            getList(){
                let _this = this;
                this.dataList = [];
                this.$api.ruleGroupList(null,function(res){
	                _this.dataList = res;
                })
            },
	        del(id) {
		        var _this = this;
		        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
		        }).then(() => {
			        _this.$api.ruleGroupDel({id:id},function(res){
				        _this.getList();
			        })
		        }).catch(() => {
		        });
	        },
        },
        mounted() {
            this.getList();
        }
    }
</script>
